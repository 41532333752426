import monsterFrame from "../assets/frames/animations_4.json";
import monsterImage from "../assets/images/animations_4.png";
import jumpFrame from "../assets/frames/animations_8.json";
import jumpImage from "../assets/images/animations_8.png";
import walkFrame from "../assets/frames/animations_9.json";
import walkImage from "../assets/images/animations_9.png";
import heroIdleAtlas from "../assets/frames/animations_2.json";
import heroIdleImage from "../assets/images/animations_2.png";
import balloonAtlas from "../assets/frames/animations_3.json";
import balloonImage from "../assets/images/animations_3.png";
import balloonFlyAtlas from "../assets/frames/animations_6.json";
import balloonFlyImage from "../assets/images/animations_6.png";
import textureAtlas from "../assets/frames/texture_atlas.json";
import textureImage from "../assets/images/texture_atlas.png";
import animation5Image from "../assets/images/animations_5.png";
import animation5Atlas from "../assets/frames/animations_5.json";
import animation10Image from "../assets/images/animations_10.png";
import animation10Atlas from "../assets/frames/animations_10.json";

const assets = {
  "animations_4_atlas": monsterFrame,
  "animations_4_image": monsterImage,
  "animations_8_atlas": jumpFrame,
  "animations_8_image": jumpImage,
  "animations_9_atlas": walkFrame,
  "animations_9_image": walkImage,
  "animations_2_atlas": heroIdleAtlas,
  "animations_2_image": heroIdleImage,
  "animations_3_atlas": balloonAtlas,
  "animations_3_image": balloonImage,
  "animations_6_atlas": balloonFlyAtlas,
  "animations_6_image": balloonFlyImage,
  "animations_5_atlas": animation5Atlas,
  "animations_5_image": animation5Image,
  "animations_10_atlas": animation10Atlas,
  "animations_10_image": animation10Image,
  "texture_atlas": textureAtlas,
  "texture_image": textureImage,
}

export default assets;