class NameGenerator {
  adjectives = [
    "Awesome",
    "Exalted",
    "Colorful",
    "Frantic",
    "Majestic",
    "Exuberant",
    "Merry",
    "Adorable",
    "Alert",
    "Amusing",
    "Bored",
    "Brave",
    "Calm",
    "Carefree",
    "Careless",
    "Chilly",
    "Clever",
    "Clumsy",
    "Cranky",
    "Crazy",
    "Excited",
    "Fantastic",
    "Foolish",
    "Friendly",
    "Fussy",
    "Fuzzy",
    "Generous",
    "Glowing",
    "Crafty",
    "Lumpy",
    "Messy",
    "Mighty",
    "Peppy",
    "Nimble",
    "Blithering",
    "Optimistic",
    "Terrible",
    "Polite",
    "Proud",
    "Quick",
    "Quiet",
    "Rusty",
    "Shining",
    "Silly",
    "Sizzling",
    "Sleepy",
    "Slimy",
    "Sloppy",
    "Smooth",
    "Sparkling",
    "Speedy",
    "Splendid",
    "Ambitious",
    "Steaming",
    "Stinky",
    "Strict",
    "Surprised",
    "Tough",
    "Tricky",
    "Wild",
    "Wise",
    "Wonderful",
    "Worried",
    "Vigorous",
    "Delightful",
    "Haunted",
    "Colossal",
    "Vivacious",
    "Infuriating",
    "Rebellious",
    "Slippery",
    "Vengeful",
    "Fierce",
    "Maniacal",
    "Affluent",
    "Decrepit",
    "Opulent",
    "Saucy",
    "Egregious",
    "Feckless",
    "Insidious",
    "Irksome",
    "Judicious",
    "Nefarious",
    "Petulant",
    "Tenacious",
    "Famous",
    "Feral",
    "Sinister",
    "Fresh",
    "Spoony",
    "Noisy",
    "Whimsical",
    "Amazing",
    "Delicate",
    "Hairless",
    "Gangly",
    "Disguised",
    "Juicy",
    "Ancient",
    "Frosty",
    "Savage",
    "Fiery",
    "Enraged",
    "Surly",
    "Dastardly",
    "Helpful",
    "Dramatic",
    "Boisterous",
    "Simple",
    "Feisty",
    "Artisinal",
    "Impolite",
    "Toasty",
    "Romantic",
    "Giggling",
    "Joyful",
    "Euphoric",
    "Humble",
    "Extreme",
    "Charming",
    "Chivalrous",
    "Grumpy",
    "Fabulous",
    "Snoozy",
    "Sarcastic",
    "Perfidious",
    "Infamous",
    "Sneaky",
    "Cheeky",
    "Sheepish",
    "Shrewd",
    "Cunning",
    "Graceful",
    "Motley",
    "Primal",
    "Ferocious",
    "Harsh",
    "Sandy",
    "Funny",
    "Gentle",
    "Elegant",
    "Fancy",
    "Scruffy",
    "Eager",
    "Jolly",
    "Obedient",
    "Thankful",
    "Witty",
    "Nervous",
    "Careful",
    "Gifted",
    "Melodic",
    "Raspy",
    "Modern",
    "Fluffy",
    "Prickly",
    "Shaggy",
    "Legendary",
    "Ghastly",
    "Heavenly",
    "Exotic",
    "Spicy",
    "Salty",
    "Fragrant",
    "Flagrant",
    "Cowardly",
    "Lonesome",
    "Elusive",
    "Excellent",
    "Magical",
    "Squirrely",
    "Beloved",
    "Forbidden",
    "Infinite",
    "Wily",
    "Squeaky",
    "Mythical",
  ];

  nouns = [
    "Turtle",
    "Fox",
    "Hawk",
    "Falcon",
    "Eagle",
    "Jester",
    "Joker",
    "Potato",
    "Goldfish",
    "Ace",
    "Flyer",
    "Driver",
    "Turkey",
    "Chicken",
    "Donkey",
    "Goat",
    "Llama",
    "Antelope",
    "Defender",
    "Weasel",
    "Hedgehog",
    "Koala",
    "Wolf",
    "Stranger",
    "Wolverine",
    "Cook",
    "Hippie",
    "Boss",
    "Goose",
    "Crawdad",
    "Toaster",
    "Pineapple",
    "Hot Dog",
    "Aardvark",
    "Wizard",
    "Waistcoat",
    "Professor",
    "Unicorn",
    "Pinecone",
    "Ninja",
    "Samurai",
    "Cowboy",
    "Vampire",
    "Werewolf",
    "Pirate",
    "Ogre",
    "Princess",
    "Mentor",
    "Narwhal",
    "Cactus",
    "Bigfoot",
    "Farmer",
    "Knight",
    "Ghost",
    "BLT",
    "Sorceror",
    "Dinosaur",
    "Songbird",
    "Baby",
    "Detective",
    "Eyeball",
    "Hamster",
    "Toad",
    "Wombat",
    "Worm",
    "Hare",
    "Zombie",
    "Mutant",
    "Mime",
    "Sheriff",
    "Toast",
    "Lollipop",
    "Pancake",
    "Milkshake",
    "Plumber",
    "Chainsaw",
    "Ice Cream",
    "Penguin",
    "Hammer",
    "Panda",
    "Octopus",
    "Wallaby",
    "Lemur",
    "Zillionaire",
    "Burrito",
    "Avenger",
    "Bobcat",
    "Platypus",
    "Kangaroo",
    "Donut",
    "Cobra",
    "Pufferfish",
    "Yodeler",
    "Diva",
    "Armadillo",
    "Blobfish",
    "Coconut",
    "Moose",
    "Tiger",
    "Walrus",
    "Buttercup",
    "Coyote",
    "Armchair",
    "Squirrel",
    "Chipmunk",
    "Magician",
    "Lion",
    "Puma",
    "Mango",
    "Cake",
    "Barbarian",
    "Ostrich",
    "Caveman",
    "Ukulele",
    "Ballerina",
    "Chestnut",
    "Furball",
    "Manatee",
    "Bullfrog",
    "Buffalo",
    "Paladin",
    "Shaman",
    "Warrior",
    "Rogue",
    "Warlock",
    "Druid",
    "Minstrel",
    "Scout",
    "Banshee",
    "Champion",
    "Eldritch",
    "Berserker",
    "Valkyrie",
    "Aristocrat",
    "Artisan",
    "Millionaire",
    "Billionaire",
    "Citizen",
    "Badger",
    "Bear",
    "Beast",
    "Centaur",
    "Coward",
    "Cyclops",
    "Hominid",
    "Goblin",
    "Kobold",
    "Gremlin",
    "Gryphon",
    "Illusion",
    "Kraken",
    "Minotaur",
    "Mystic",
    "Nomad",
    "Pangolin",
    "Peasant",
    "Pegasus",
    "Rhino",
    "Sapling",
    "Yeti",
    "Sphinx",
    "Villain",
    "Warthog",
    "Squire",
  ];

  validAdjective(str) {
    return this.adjectives.some(it => it === str);
  };

  validNoun(str) {
    return this.nouns.some(it => it === str);
  };

  generateName() {
    const randomAdjective = this.adjectives[Math.floor(Math.random() * this.adjectives.length)];
    const randomNoun = this.nouns[Math.floor(Math.random() * this.nouns.length)];
    return `${randomAdjective} ${randomNoun}`;
  };
}

export default NameGenerator;