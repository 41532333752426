import React from "react";
import "../assets/css/loading.css";
import clsx from "clsx";
import style from "./LoadingWithText.module.scss";

function LoadingWithText({transparent}) {
  return (
    <div className={clsx(style.Backdrop, transparent ? style.Transparent : null)}>
      <div className={clsx(style.Wrapper)}>
        <div className={clsx(style.Text)}>
          Loading
        </div>
        <div className={clsx(style.LdsEllipsis)}>
          <div/>
          <div/>
          <div/>
          <div/>
        </div>
      </div>
    </div>
  )
}

export default LoadingWithText;
